@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.view-approved-wrapper{
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    @include square(100%);
    background-color:rgba(138, 138, 138, 0.3);
}
.view-approved-responses-container{
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
    width: 30vw;
    height: 100vh;
    @include flex(column,space-between,flex-start);
    background-color: $black-800;
    transform: translate(0%,0%);
    transition: transform 500ms ease-in-out;
    gap:0.5rem;
    &__header{
        padding: 1rem;
        font-size:1.2rem;
        @include flex(row,space-between,center);
        flex:0.5;
        width:100%;
        img{
            @include square(1.2rem);
        }
        .close_btn{
            cursor: pointer;
        }
    }
    &__content{
        padding: 0.5rem 1rem;
        flex:10;
        @include flex(column,flex-start,flex-start);
        gap:2rem;
        width: 100%;
        overflow-y: auto;
        max-height: 90vh;
        @include scrollbars(0.3rem, lighten($grey-450, 15%), $grey-300);
        font-size: 0.8rem;
        .approved-response-container{
            @include flex(column,flex-start,flex-start);
            gap: 1rem;
            border-bottom: 1px solid $grey-350;
            //padding-bottom: 0.5rem;
            width: 100%;
            span{
                color:rgb(255,255,255,0.7);
            }
            &__link{
                @include flex(column,flex-start,center);
                cursor: pointer;
                gap:0.5rem;
                span{
                    font-size:0.8rem;
                }
                .text-copied{
                    background-color: $white;
                    padding: 0.3rem;
                    color:$black;
                    border-radius: 0.4rem;
                    z-index: 1;
                    position: relative;
                    font-size: 0.8rem;  
                    top:-0.75rem; 
                    transition: opacity 0.2s;
                    opacity: 1;
                    &.hide{
                        visibility: hidden;
                        opacity: 0;
                    }     
                }
                .action-select{
                    position: relative;
                    top:-1rem;
                }
                .action-select,button{
                    background: transparent;
                    border: transparent;
                    color: white;
                    padding: 0;
                    @include flex(row,flex-start,center);
                    gap: 0.5rem;
                    font-size: 0.8rem;               
                }
                button[disabled],button:disabled{
                    color:$disabled-btn-color;
                }
            }
            &__content{
                overflow-y: auto;
                max-height: 20rem;
                @include scrollbars(0.3rem, lighten($grey-450, 15%), $grey-300);
                &.fill-content{
                    max-height:70vh;
                }

            }

        }
        span.no-data{
            position: absolute;
            top: 45%;
            left: 16%;
        }
    }
    &__footer{
      @include flex(row,flex-start,flex-start);
      flex:0.5;
      padding: 0.8rem;
      background: $grey-600;
      align-self: flex-end;
      width: 100%;
      button{
        background: transparent;
        border: 1px solid white;
        color: white;
        padding: 0.6rem 1.5rem;
        font-weight: 700;
      }
    }
}