@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.welcome-wrapper{
    @include flex(row, center, flex-start);
    @include background($background-image-dark );
    background-position: 0 -6rem;
    background-size: cover;
    &__container{
        @include size(80%,100vh);
        header{
            color: $white;
            padding: 2rem 0;
            font: {
                size:1.2rem;
            }
            span{
                font: {
                    weight:600;
                }
            }
        }
        section{
            @include flex(row, space-between, center);
            height: calc(100vh - 6rem);
            gap: 10rem;
            .notes{
                color:white;
                h1{
                    font: {
                        size:3.2rem;
                        weight:600;
                    }
                }
                p{
                    margin: 2rem 0;
                    color: $white;
                    font: {
                        size:.85rem;
                    }
                    line-height: 1.2rem;
                    position: relative;
                    padding-left: 1.5rem;
                    &:before{
                        content: "";
                       @include size(.25rem,100%);
                        background: $yellow-400;
                        display: block;
                        position: absolute;
                        left: .5rem;
                    }
                }
                button {
                    background: $yellow-400;
                    color: $grey-500;
                    font: {
                        size:.8rem;
                        weight:600;
                    }
                    padding: 1rem;
                    min-width: 16rem;
                    margin: 0 0 7px;
                    border: none;
                    cursor: pointer;
                    outline: none;
                    border-radius: .6rem;
                    box-shadow: 0 5px darken($yellow-400,6%), 0 5px 5px 1px #4f4f4f6e;
                    transition: box-shadow 0.1s, margin 0.1s;
                    &:active {
                        margin: 7px 0 0;
                        box-shadow: 0 0 darken($yellow-400,5%), 0 0 #2f2f2f, 0 3px 14px darken($yellow-400,2%) inset;
                      }
                  }
            }
            div.bot-logo{
                animation: up-down 1.4s infinite ease-in-out alternate;
                @include square(19rem);
                img{
                    
                }
                .bot-label{
                    position: absolute;
                    background: white;
                    min-width: 9rem;
                    height: 2.2rem;
                    bottom: -1rem;
                    right: 0;
                    border-radius: 0.88rem;
                    border-top-left-radius: 0;
                    max-width: 11rem; 
                    padding: 0.3rem 0.8rem 0.3rem 0.3rem;
                    @include flex(row,flex-start,center);
                    gap: 0.3rem;
                    &__text{
                        font-size: 0.75rem;
                        font-weight: 600;
                    }       
                }
            }
            
        }
    }
}
@keyframes up-down{
    from{
      transform: translatey(0px);
    }
    to{
      transform: translatey(-20px);
    }
  }