@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.gpo-pdf-viewer-wrapper.full-screen{
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    @include square(100%);
    background-color:rgba(138, 138, 138, 0.3);
}
.gpo-pdf-viewer {
  // width: 650px;
  position: relative;
  align-self: flex-start;
  z-index:999;
  &.full-view{
    width: 45%;
    float: right;
    top: 0rem;
    height: 100vh;
    .doc{
      height: calc(100vh - 1rem);
    }
    .loader-wrapper {
      height: calc(100vh - 1rem);
    }
    .react-pdf__Document {
      max-height: calc(100vh - 2rem);
    }
    canvas {
      height: calc(100vh - 4rem) !important;
    }
  }
  .loader-wrapper {
    position: absolute;
    width:99%;
    height: calc(100vh - 10rem);
    margin-left: 1.8rem;
  }
  .doc{
    margin-top: 1rem;
    //border:0.05rem solid #C4C4CD;
    height: calc(100vh - 8rem);
    .doc-header{
      @include flex(row,flex-start,center);
    }
    .doc-close{
      padding: 0.4rem 0.6rem;
      background: $white;
      border-radius: 4px 0 0 4px;
      color: $black;
      font-weight: bold;
      height:2rem;
      cursor: pointer;
    }
  }
  .gpo-pdf-header {
    height:2rem;
    background-color: $black-800;
    color: $yellow-400;
    font-weight: bold;
    padding: 0.5rem;
    padding-left: 2rem;
    width:100%;
    @include flex(row, space-between, center);
    span {
      @include flex(row, center, center);
      cursor: pointer;
      font: {
        size: 0.8rem;
      }
      img {
        margin-right: 0.3rem;
        @include square(0.7rem);
      }
    }
    .close_btn{
      margin-left:auto;
    }
  }
  .react-pdf__Document {
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 1rem;
    margin-left: 1.8rem;
    background-color: $black-800;
    max-height: calc(100vh - 9rem);
    @include scrollbars(0.3rem, lighten($grey-450, 15%), $grey-300);

  }
  canvas {
    width: 100% !important;
  }
}
.fullscreen-modal {
  z-index:10;
  position: fixed;
  left: 0;
  top: 0;
  @include square(100%);
  background-color: $white;
  header {
    @include flex(row, space-between, center);
    background-color: $grey-600;
    padding: 1rem;
    color: $white;
    img {
      cursor: pointer;
    }
  }
  &__body {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 3rem);
    @include scrollbars(0.6rem, darken($grey-600, 5%), $grey-450);
    .rpv-core__inner-pages {
      @include scrollbars(0.6rem, darken($grey-600, 5%), $grey-450);
    }
  }
}
