@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.certified-response-wrapper{
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
    @include square(100%);
    background-color:rgba(138, 138, 138, 0.4);
}
.certified-response-container{
    position: fixed;
    top: 40%;
    left: 40%;
    width:35rem;
    z-index: 1001;
    @include flex(column,space-between,flex-start);
    background-color: #23232F;
    transform: translate(0%,0%);
    transition: transform 500ms ease-in-out;
    &__header{
        padding: 1rem;
        font-size:1.2rem;
        @include flex(row,space-between,center);
        flex:0.5;
        width:100%;
        img{
            @include square(1.2rem);
        }
        .close_btn{
            cursor: pointer;
        }
    }
    &__content{
        width: 100%;
        @include flex(column,flex-start,flex-start);
        flex:10;
        gap:1rem;
        padding: 3rem 1rem;
    }
    &__footer{
      @include flex(row,flex-start,flex-start);
      flex:0.5;
      padding: 1rem;
      align-self: flex-end;
      width: 100%;
      gap:1rem;
      border-top: 0.05rem solid #3A3A4A;
      button{
        background: transparent;
        border: 1px solid white;
        color: white;
        padding: 0.6rem 1.5rem;
        font-weight: 700;
        &.primary{
          background: $yellow-400;
          border-color: $yellow-400;
          color: $black;
        }
      }
    }
}