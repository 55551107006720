@import 'assets/scss/mixins';
@import 'assets/scss/variables';


.material-input {
    position: relative;
    width: 100%;
    &:not(:last-child) {
        margin-bottom: 1.5rem;
    }
    textarea {
        font-family: 'EYInterstate W04 Light';
        background-color: $grey-600;
        @include scrollbars(0.3rem, lighten($grey-450, 15%), $grey-300);
    }
    textarea {
        color: $white;
        font-size: 0.9rem;
        padding: 1.2rem 0.9rem 0.9rem 0.7rem;
        position: relative;
        display: block;
        border: none;
        width: 100%;
        border-bottom: 0.06rem solid $white;
        box-sizing: border-box;
        &:focus {
            outline: none;
        }
    }
    
    textarea:focus + label,
    textarea:valid + label,
    textarea:not(:empty) + label
    {
        top: 0.4rem;
        font-size: 0.75rem;
        background-color: $grey-600;
        left: 0;
        padding-left: 0.75rem;
    }
    textarea {
        min-height: 4rem;
        resize: none;
        
    }
    textarea:focus,textarea:valid,textarea:not(:empty){
        position: relative;
        top: 1.3rem;
        padding-top: 0rem;
        margin-bottom: 1rem;
    }
    label {
        color: $white;
        font-size: 0.75rem;
        font-weight: 300;
        position: absolute;
        pointer-events: none;
        top: 14px;
        transition: 0.2s ease all;
        width: 100%;
        left: 0.75rem;
    }
    &.disabled{
        label{
        color : $white !important;
        }
        textarea{
        color : $white !important;
        //border-bottom:none !important;
        }

    }
    .error-message{
        font-size: 0.8rem;
        color:$red-300;
    }
}