@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";
@import "/src/assets/scss/typography";

.upload__modal {
    width: 100%;
    height: 100vh;
    @include flex(row, center, center);
    background: rgba(0, 0, 0,0.7);
    position: absolute;
    color:black;
    animation: move .3s ease;
    box-shadow: toRem(2px) toRem(1px) toRem(2px) toRem(2px) rgb(0 0 0 / 5%);
    border-radius: 0.3rem;
    z-index:100;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;

    &__container {
        @include flex(column, center, flex-start);
        height: toRem(400px);
        width: toRem(650px);
        background: $grey-600;
        color: $white;

        &__header {
            height: toRem(40px);
            width: 100%;
            padding: toRem(20px);
            @include flex(row, space-between, center);
            img:hover{
                cursor: pointer;
            }
        }

        &__content {
            @include flex(column, flex-start, center);
            gap: toRem(10px);
            height: toRem(280px);
            width: 100%;
            padding: toRem(10px) toRem(40px) toRem(20px) toRem(20px);
            font-size: toRem(10px);
            
            &__container{
                @include flex(column, flex-start, center);
                gap: toRem(10px);
                height: 100%;
                width: 100%;
            overflow-y: auto;
            @include scrollbars(3px, $grey-300);
            }
            &__header{
                @include flex(row, space-between, center);
                gap: toRem(10px);
                width: 100%;
                height: toRem(40px);
                opacity: .4;
                
                &__item {
                    flex-basis: toRem(100px);
                    padding: toRem(10px);
                }
                
                &__item:nth-child(2) {
                    flex-basis: toRem(200px);
                    padding: toRem(10px);
                }
            }

            &__row {
                @include flex(row, space-between, center);
                gap: toRem(10px);
                width: 100%;
                height: toRem(40px);
                background: $grey-500;
                
                &__item {
                    flex-basis: toRem(100px);
                    padding: toRem(10px);
                }

                &__item:nth-child(2) {
                    flex-basis: toRem(200px);
                    padding: toRem(10px);
                }
            }

        }

        &__footer {
            height: toRem(60px);
            width: 100%;
            border-top: toRem(1px) solid $white;
            padding: toRem(20px);

            &__button {
                width: toRem(75px);
                padding: toRem(10px) toRem(25px) toRem(10px) toRem(25px);
                border: toRem(1px) solid $white;
                font-size: toRem(10px);
            }
            &__button:hover{
                cursor: pointer;

            }

        }
    }
}
.source{
@include flex(row, flex-start, center);
gap: toRem(10px);
width: 100%;

&__URL{
width: toRem(100px);
    overflow: hidden;
    text-overflow: ellipsis;
}
}