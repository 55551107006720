@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";
@import "/src/assets/scss/typography";
.status__conatainer{
    @include flex(row, flex-start, center);
    gap: toRem(10px);
    height: 100%;
    width: 100%;
    &__name{
        font-size: toRem(8px);
    }
}

.circle{
    @include square(toRem(9px));
    border-radius: 50%;
    background-color: #bbb;
}
.circle__green{
    @extend .circle;
    background-color: $green-400;
}
.circle__orange{
    @extend .circle;
    background-color: $orange-200
}