@import 'assets/scss/mixins';
@import 'assets/scss/variables';

.ai-file-uploader {
  display: inline-block;
  width: 100%;
  text-align: center;
  position: relative;
  box-shadow: var(--file-uploader--box-shadow);
  background-color:$app-bg;
  border:0.1rem dashed $grey-350;
  padding: 1rem;

  .file-uploader-label-text {
    font-family: inherit;
    color: $grey-300;
  }

  &:hover {
    --file-uploader--color: var(--file-uploader-hover--color);
    --file-uploader--box-shadow: var(--file-uploader-hover--box-shadow);
  }

  &:focus {
    --file-uploader--color: var(--file-uploader-focus--color);
    --file-uploader--box-shadow: var(--file-uploader-focus--box-shadow);
  }

  .icon {
    width: 2.5rem;
    height: auto;
    fill: var(--file-uploader-icon-color);

    svg {
      width: 100%;
      height: 100%;
    }

    &:hover {
      fill: var(--file-uploader-hover-icon-color);
    }

    &:focus {
      fill: var(--file-uploader-focus-icon-color);
    }
  }

  &.invalid {
    .dropzone-container {
      background-image: none;
      border-color: var(--file-uploader-error--border-color);
      border-width: var(--file-uploader-error--border-width);
      border-style: var(--file-uploader-error--border-style);
      --file-uploader--bg-color: var(--file-uploader-error--bg-color);
      --file-uploader--color: var(--file-uploader-error--color);
      --file-uploader--box-shadow: var(--file-uploader-error--box-shadow);
    }

    .icon {
      --file-uploader-icon-color: var(--file-uploader-error-icon-color);
    }

    .file-uploader-label-text {
      --file-uploader--color: $grey-300;
    }
  }

  &.disabled {
    --file-uploader--bg-color: var(--file-uploader-disabled--bg-color);
    --file-uploader--box-shadow: var(--file-uploader-disabled--box-shadow);

    cursor: not-allowed;

    > div {
      pointer-events: none;
    }

    .dropzone-container {
      background-image: linear-gradient(to right, var(--file-uploader-disabled--border-color) 50%, transparent 50%),
        linear-gradient(to right, var(--file-uploader-disabled--border-color) 50%, transparent 50%),
        linear-gradient(to bottom, var(--file-uploader-disabled--border-color) 50%, transparent 50%),
        linear-gradient(to bottom, var(--file-uploader-disabled--border-color) 50%, transparent 50%);
      background-color: $app-bg;
    }

    .icon {
      fill: var(--file-uploader-disabled-icon-color);
    }

    .file-uploader-label-text {
      color: $grey-300;
    }
  }
}

.dropzone-container {
  width: 100%;
  height: 100%;
  border: none;
  font-size: 0.8rem;
  color: $grey-300;
  font-weight: normal;
  background-color: $app-bg;
  background-image: linear-gradient(to right, var(--file-uploader--border-color) 50%, transparent 50%),
    linear-gradient(to right, var(--file-uploader--border-color) 50%, transparent 50%),
    linear-gradient(to bottom, var(--file-uploader--border-color) 50%, transparent 50%),
    linear-gradient(to bottom, var(--file-uploader--border-color) 50%, transparent 50%);
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 20px 1px, 20px 1px, 1px 20px, 1px 20px;
  padding: 1.8rem;
  cursor:pointer;
  &.disableDrop{
    cursor:none;
    pointer-events:none;
  }
  &:hover {
    --file-uploader--bg-color: var(--file-uploader-hover--bg-color);

    background-image: linear-gradient(to right, var(--file-uploader-hover--border-color) 50%, transparent 50%),
      linear-gradient(to right, var(--file-uploader-hover--border-color) 50%, transparent 50%),
      linear-gradient(to bottom, var(--file-uploader-hover--border-color) 50%, transparent 50%),
      linear-gradient(to bottom, var(--file-uploader-hover--border-color) 50%, transparent 50%);
  }

  &:focus {
    --file-uploader--bg-color: var(--file-uploader-focus--bg-color);

    background-image: linear-gradient(to right, var(--file-uploader-focus--border-color) 50%, transparent 50%),
      linear-gradient(to right, var(--file-uploader-focus--border-color) 50%, transparent 50%),
      linear-gradient(to bottom, var(--file-uploader-focus--border-color) 50%, transparent 50%),
      linear-gradient(to bottom, var(--file-uploader-focus--border-color) 50%, transparent 50%);
  }
}

.file-uploader-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ai-file-uploader-items{
    @include flex(column,flex-start,flex-start);
    width: 100%;
    gap: 1rem;
    &__item{
        font-size: 0.8rem;
        background-color: $app-bg;
        padding: 1rem;
        width:100%;
        @include flex(column,space-between,flex-start);
        gap: 0.5rem;
        .item-details{
            @include flex(row,space-between,center);
            width: 100%;
            &__fileIcon{
                @include flex(row,flex-start,center);
                gap: 0.5rem;
            }
            &__size{
                @include flex(row,flex-start,center);
                font-size: 0.7rem; 
                gap:2rem;
                .remove,.icon{
                  font-weight:bold;
                  font-size: 0.8rem;
                  cursor: pointer;
                }
            }
        }
        
        .item-size-progress{
                height:0.2rem;
                width:100%;
                background-color: $grey-350;
                .progressBar{
                    height:100%;
                    background-color: $yellow-400;
                }
        }
        &.error-item{
          border:0.1rem solid $red-300;
        }
    }
    &__itemprogress{
      width: 99.7%!important;
      margin-top:-1rem;
      height:0.2rem;
      width:100%;
      background-color: $grey-350;
      .progressBar{
          height:100%;
          background-color: $yellow-400;
      }      
    }
    &__errorMessage{
      width: 99.7%!important;
      margin-top: -1rem;
      font-size: 0.8rem;
      color: $red-300;
      font-weight: 100;
      @include flex(row,flex-start,center);
      gap:0.5rem;
      img{
        @include size(1.2rem,1.2rem);
      }
    }
}