@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.gpo-chat-history{
    padding: 0 1rem;
    section {
      @include flex(column, flex-start, flex-start);
      header {
        @include flex(row, flex-start, center);
        gap: 1rem;
        margin-bottom: 0.75rem;
        .name {
          @include flex(column, center, flex-start);
          line-height: 1rem;
          strong {
            color: $white;
            font: {
              size: 0.7rem;
            }
          }
          span {
            color: $grey-700;
            font: {
              size: 0.6rem;
            }
          }
        }
        img {
          @include square(2.5rem);
        }
      }
      .message-section{
        @include flex(row, flex-start, flex-start);
        gap: 0.5rem;
      //   .chatContent{
      //     @include flex(row,flex-start,flex-start);
      //     gap:1rem;
      //     padding: 1.5rem 0.65rem !important;
      //     max-width:53rem!important;
      //     &__untrained{
      //         flex:2;
      //         @include flex(column,flex-start,flex-start);
      //         gap:1.5rem;
      //         padding: 0 1rem 1rem 1rem;
      //         border-right: 1px solid #4E515B;
      //         .header{
      //             font-weight:700;
      //         }
      //         .content{
      //             padding-right: 1rem;
      //             height:13rem;
      //             overflow-y: auto;
      //             @include scrollbars(0.3rem, lighten($grey-450, 15%), $grey-300);
      //         }
      //         .actions{
      //             @include flex(column,flex-start,flex-start);
      //             gap:1rem;
      //             button[disabled],button:disabled{
      //               color:$disabled-btn-color;
      //             }
      //             &__add{
      //                 background: transparent;
      //                 border: transparent;
      //                 color: white;
      //                 padding: 0;
      //                 @include flex(row,flex-start,center);
      //                 gap: 0.5rem;
      //                 font-size: 0.8rem;               
      //             }
      //             &__view{
      //                 background: transparent;
      //                 border: 1px solid white;
      //                 color: white;
      //                 padding: 0.5rem;
      //                 @include flex(row,flex-start,center);
      //                 font-weight: 700;
      //                 gap: 0.5rem;            
      //             }
      //         }
      //     }
      //     &__trained{
      //         flex:2;
      //         @include flex(column,flex-start,flex-start);
      //         gap:1.5rem;
      //         padding: 0 1rem;
      //         .header{
      //             font-weight:700;
      //         }
      //         .content{
      //             padding-right:1rem;
      //             height:13rem;
      //             overflow-y: auto;
      //             @include scrollbars(0.3rem, lighten($grey-450, 15%), $grey-300);
      //         }
      //         .actions{
      //             @include flex(column,flex-start,flex-start);
      //             gap:1rem;
      //             width:100%;
      //             button[disabled],button:disabled{
      //               color:$disabled-btn-color;
      //             }
      //             &__doc{
      //                 @include flex(row,flex-start,center);
      //                 gap:0.5rem;
      //                 align-self: flex-end;
      //                 right: -2.8rem;
      //                 .doc-button{
      //                   cursor: pointer;
      //                 }
      //             }
      //             &__add{
      //                 background: transparent;
      //                 border: transparent;
      //                 color: white;
      //                 padding: 0;
      //                 @include flex(row,flex-start,center);
      //                 gap: 0.5rem;
      //                 font-size: 0.8rem;               
      //             }
      //         }
      //     }
      //     &__maximize{
      //         flex:0.1;
      //         img{
      //             cursor: pointer;
      //         }
      //     }
      // }
      }
      .message-footer{
            margin-top: 0.5rem;
            @include flex(row, flex-end, center);
              .gpo-icon {
                display: flex;
                right: 4.5rem;
                cursor: pointer;
                position: relative;
                img {
                  @include square(0.85rem);
                }
              }
              .gpo-pop-up {
                color:$white;
                position: absolute;
                top: -2rem;
              }
      }
      p.chatMessage,.chatContent {
        border-radius: 1rem;
        background-color: $black-800;
        color: $white;
        font-size: 0.8rem;
        padding: 1rem 1.5rem;
        //display: inline-block;
        max-width: 30rem;
        &.selectedSearch{
          border:0.2rem solid $yellow-400 !important;
        }
        
      }
      &.bot,&.agent {
        p.chatMessage,.chatContent{
        border-top-left-radius: 0.3rem !important;
        }
        .user-action {
            button {
              pointer-events: none;
              border: none;
              outline: none;
              background: transparent;
              cursor: pointer;
              padding: 0 0.2rem;
              &:last-child {
                transform: rotate(180deg);
              }
              img {
                @include square(1.1rem);
              }
            }
          }
      }
      &.user {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        position: relative;
        right:1rem;
        p.chatMessage {
          background-color: $yellow-400;
          border-top-right-radius: 0.3rem !important;
          color: $grey-500;
          box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.07);
        }
        .name{
          color:$white;
          align-items: flex-end;
        }
        & > span{
          margin: 1rem 0;
          cursor: pointer;
          color: $grey-700;
          font-size: .8rem;
          padding: .6rem 1rem;
        }
      }
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      &.new-agent {
        @include flex(row, center, center);
        margin: 2.5rem 0 1rem;
        .detail {
        //   @include flex(column, center, center);
        //   background-color: $white;
        //   border: 0.06rem solid $grey-300;
        //   border-radius: 1rem;
        //   color: $app-bg;
        //   padding: 1.5rem 1.5rem 1rem;
        //   position: relative;
        //   min-width: 15rem;
        //   img {
        //     @include square(2.2rem);
        //     position: absolute;
        //     top: -1.2rem;
        //   }
        //   span {
        //     line-height: 1rem;
        //     &:last-child {
        //       font-size: 0.6rem;
        //       color: $white;
        //     }
        //   }
        //   & > span {
        //     font: {
        //       size: 0.75rem;
        //     }
        //   }
        @include flex(column, center, center);
            min-width: 22rem;
            background-color: $white;
            padding: 1.5rem 0 1rem;
            border-radius: 1rem;
            border: 0.06rem solid $grey-300;
            position: relative;
            img {
              position: absolute;
              border-radius: 50%;
              top: -1.25rem;
              @include square(2.2rem);
            }
            span {
              font-size: 0.8rem;
              line-height: 1rem;
              &:last-child {
                font: {
                  size: 0.65rem;
                }
                color: lighten($grey-450, 30%);
              }
            }
        }
      }
      &.agent-left {
        @include flex(row, center, center);
        margin: 1rem 0;
        .center {
          @include flex(row, center, center);
          img {
            margin-right: 0.5rem;
          }
          span {
            color: $app-bg;
            font-size: 0.8rem;
          }
        }
      }
      &:last-child{
        margin-bottom: 1rem;
      }
    }
  }