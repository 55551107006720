@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.provide-feedback-wrapper{
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    @include square(100%);
    background-color:rgba(138, 138, 138, 0.1);
}
.provide-feedback-container{
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
    width: 30vw;
    height: 100vh;
    @include flex(column,space-between,flex-start);
    background-color: $black-800;
    transform: translate(0%,0%);
    transition: transform 500ms ease-in-out;
    &__header{
        padding: 1rem;
        font-size:1.2rem;
        @include flex(row,space-between,center);
        flex:0.5;
        width:100%;
        img{
            @include square(1.2rem);
        }
        .close_btn{
            cursor: pointer;
        }
    }
    &__content{
        width: 100%;
        @include flex(column,flex-start,flex-start);
        flex:10;
        gap:1.5rem;
        padding: 1rem;
        font-size:0.9rem!important;
        height: calc(100vh - 10rem);
        overflow-y: auto;
        @include scrollbars(0.3rem, lighten($grey-450, 15%), $grey-300);
        .content-feedback{
            width:100%;
        }
        .content-info {
            width:100%;
            @include flex(column,flex-start,flex-start);
            gap:0.5rem;
            .material-input label{
                font-size:0.9rem!important;
            }
            textarea{
                font-size: 0.9rem!important;
            }
            label {
              display: flex;
              align-items: center;
              position: relative;
              font-size: 0.9rem!important;
            }
  
            input {
              display: none;
            }
  
            label > span {
              @include square(1.3rem);
              display: flex;
              justify-content: center;
              border: 0.06rem solid $white;
              margin-right: 0.5rem;
              transition: all 0.3s;
            }
  
            input:checked + label > span {
              border: 0.06rem solid transparent;
              animation: bounce 250ms;
            }
  
            input:checked + label > span::before {
              content: "";
              position: absolute;
              top: 0.5rem;
              left: 4px;
              border-right: 0.1rem solid transparent;
              border-bottom: 0.1rem solid transparent;
              transform: rotate(45deg);
              transform-origin: 0% 100%;
              animation: checked-box 125ms 250ms forwards;
            }
  
            input:checked + label > span {
              background: $grey-500;
            }
  
            @keyframes checked-box {
              0% {
                width: 0;
                height: 0;
                border-color: #fff;
                transform: translate(0, 0) rotate(45deg);
              }
              33% {
                width: 4px;
                height: 8px;
                border-color: #fff;
                transform: translate(0, 0) rotate(45deg);
              }
              100% {
                width: 6px;
                height: 10px;
                border-color: #fff;
                transform: translate(0, -8px) rotate(45deg);
              }
            }
  
            @keyframes bounce {
              0% {
                transform: scale(1);
              }
              33% {
                transform: scale(0.7);
              }
              100% {
                transform: scale(1);
              }
            }
          }
          .content-link{
            width:100%;
          }
        .content-tabs{
            width: 100%;
            @include flex(row,flex-start,flex-start); 
            gap:2rem; 
            &__radio{
                font-size:0.8rem;
                @include flex(row,flex-start,center); 
                gap: 0.3rem;
                input[type=radio]{
                    appearance: none;
                    -webkit-appearance: none;
                    border-radius: 50%;
                    background: $black-800;
                    border: 1px solid #FFF;
                    width: 14px;
                    height: 14px;
                }
                input[type=radio]:checked{
                    appearance: none;
                    -webkit-appearance: none;
                    border-radius: 50%;
                    background: white;
                    border: 0.15rem solid $black-800;
                    outline: 1px solid #fff;
                    width: 14px;
                    height: 14px;
                }
            }
        }
        .content-tabs-data{
            @include flex(column,flex-start,flex-start); 
            gap:1rem;
            width:100%;
            .ai-file-uploader{
               background-color: $grey-600;
               padding:0;
               .dropzone-container{
                background-color: $grey-600;
               }
            }
            .ai-file-uploader-items__item{
                background-color: $grey-450;
            }
        }
    }
    &__footer{
      @include flex(row,flex-start,flex-start);
      flex:0.5;
      padding: 1rem;
      background: $grey-600;
      align-self: flex-end;
      width: 100%;
      gap:1rem;
      button{
        background: transparent;
        border: 1px solid white;
        color: white;
        padding: 0.6rem 1.5rem;
        font-weight: 700;
        &.primary{
          background: $yellow-400;
          border-color: $yellow-400;
          color: $black;
          &:disabled{
            background:rgb(255,255,255,0.5);
            border-color: rgb(255,255,255,0.5);
          }
        }
      }
    }
}