@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.divider {
    margin: 1rem 0;
  &__container {
    @include flex(row, center, center);
    position: relative;
    width: 100%;
    &:before {
      content: "";
      @include size(94%, 0.05rem);
      background-color: lighten($grey-300, 5%);
      position: absolute;
      left: 4%;
      top: .5rem;
    }
    span {
      font-size: 0.75rem;
      background-color: $bg-color-dark;
      z-index: 1;
      padding: 0 1rem;
    }
  }
}
