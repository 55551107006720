@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.add-approved-wrapper{
    background-color:rgba(138, 138, 138, 0.3) !important;
}
.response-recommendation-container{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
    @include square(100%);
    @include flex(column,flex-start,flex-start);
    background-color: $grey-600;
    padding: 1rem;
    &__header{
        font-size:1.2rem;
        @include flex(row,space-between,center);
        width:100%;
        img{
            @include square(1.2rem);
        }
        .close_btn{
            cursor: pointer;
        }
    }
    &__content{
        @include flex(row,flex-start,flex-start);
        padding: 0 1rem 1rem 1rem;
        margin: 3rem 1rem 1rem 1rem;
        overflow-y: auto;
        overflow-x:hidden;
        @include scrollbars(0.3rem, lighten($grey-450, 15%), $grey-300);
        .trained{
            flex:2;
            @include flex(column,flex-start,flex-start);
            gap:1rem;
            padding: 0 1rem 1rem 1rem;
            .header{
                font-weight:700;
            }
            .content{
                width: 100%;
                @include flex(column,flex-start,flex-start);
                gap:1rem;
                padding:1rem;
                background-color:$black-800;
                span.message{
                    padding-right: 1rem;
                    min-height:10rem;
                    overflow-y: auto;
                    max-height: 75vh;
                    font-size: 0.9rem;
                    @include scrollbars(0.3rem, lighten($grey-450, 15%), $grey-300);
                }
                .actions{
                    @include flex(column,flex-start,flex-start);
                    gap:1rem;
                    cursor:pointer;
                    //padding-left: 1rem;
                    .action:not(:first-child){
                        border-left: 1px solid $grey-350;
                        padding-left: 1rem;
                    }
                    
                    .action-select{
                        @include flex(row,flex-start,center);
                        font-size: 0.8rem;
                        padding-right: 1rem;
                        gap:0.5rem;
                    }
                    .action-copy{
                        .text-copied{
                            background-color: $white;
                            padding: 0.3rem;
                            color:$black;
                            border-radius: 0.4rem;
                            z-index: 1;
                            position: relative;
                            font-size: 0.8rem;  
                            top:-1.5rem; 
                            transition: opacity 0.2s;
                            opacity: 1;
                            &.hide{
                                visibility: hidden;
                                opacity: 0;
                            }     
                        }
                        .action-select{
                            position: relative;
                            top:-1rem;
                        }
                        .actions__add,.action-select{
                            background: transparent;
                            border: transparent;
                            color: white;
                            padding: 0;
                            @include flex(row,flex-start,center);
                            gap: 0.5rem;
                            font-size: 0.8rem;               
                        }
                        button[disabled],button:disabled{
                            color:$disabled-btn-color;
                        }
                    }
                    .action-others{
                        @include flex(row,flex-start,center);
                        font-size: 0.8rem;
                        gap:1rem;
                        button{
                            background: transparent;
                            color: white;
                            border: 1px solid white;
                            padding: 0.2rem 0.5rem;
                            min-width: 10rem;
                            @include flex(row,flex-start,center);
                            gap: 0.2rem;
                            font-size: 0.8rem;   
                        }
                    }
                    
                }
            }
            .confidence{
                @include flex(row,flex-start,center);
                gap:0.5rem;
                font-size: 0.8rem;
                align-self: flex-end;
                .doc-confidence.excellent{
                    color: $green-400;
                }
                .doc-confidence.medium{
                    color: $grey-300;
                }
                .doc-confidence.low{
                    color: $red-300;
                }
            }
            .actions__doc{
                position: relative;
                @include flex(row,flex-start,center);
                gap:0.5rem;
                font-size: 0.8rem;
                align-self: flex-end;
                .citation-modal{
                    background-color: $black;
                    position: absolute;
                    //top: -7rem;
                    padding: 0.5rem;
                    z-index: 1;
                    bottom: 125%;
                    // /left:-6rem;
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity 0.3s;
                    @include flex(row,flex-start,flex-start);
                    max-width:15rem;
                    gap:2rem;
                    &__label{
                        flex: 3;
                        @include flex(column,flex-start,flex-start);
                        gap:1rem;
                    }
                    &__values{
                        flex:3;
                        @include flex(column,flex-start,flex-start);
                        gap:1rem;
                    }
                    .doc-value{
                        cursor:pointer;
                        cursor: pointer;
                        word-wrap: break-word;
                        display: inline-block;
                        width: 8rem;
                    }
                    &__score{
                        @include flex(row,flex-start,center);
                        gap:1rem;
                    }
                    &__source{
                        @include flex(row,flex-start,center);
                        gap:1rem;
                    }
                    &__pages{
                        @include flex(row,flex-start,center);
                        gap:1rem;
                    }
                   &::after {
                    content: "";
                    position: absolute;
                    top: 98%;
                    left: 40%;
                    margin-left: -5px;
                    border-width: 10px;
                    border-style: solid;
                    border-color: $black transparent transparent transparent;
                  }
                }
                &:hover .citation-modal{
                    visibility: visible;
                    opacity: 1;
                }
                .doc-confidence.excellent{
                    color: $green-400;
                }
                .doc-confidence.medium{
                    color: $grey-300;
                }
                .doc-confidence.low{
                    color: $red-300;
                }
            }

        }
        .approved-responses{
            flex:2;
            @include flex(column,flex-start,flex-start);
            gap:1rem;
            padding: 0 1rem 1rem 1rem;
            .header{
                font-weight:700;
            }
            .content{
                width: 100%;
                @include flex(column,flex-start,flex-start);
                gap:0.5rem;
                .approved-response-container{
                    @include flex(column,flex-start,flex-start);
                    background-color:$black-800;
                    padding:1rem;
                    gap: 1rem;
                    padding-bottom: 0rem;
                    width: 100%;
                    font-size: 0.9rem;
                    flex:1;
                    //@include scrollbars(0.3rem, lighten($grey-450, 15%), $grey-300);
                    &__content{
                        max-height:13.5rem;
                        overflow-y: auto;
                        @include scrollbars(0.3rem, lighten($grey-450, 15%), $grey-300);
                        &.fill-content{
                            max-height:70vh;  
                        }
                    }
                    &__date{
                        font-size:0.8rem;
                        color:rgb(255,255,255,0.7);
                    }
                    &__link{
                        @include flex(row,flex-start,center);
                        gap:0.5rem;
                        span{
                            font-size:0.8rem;
                        }
                        .link-action{
                            @include flex(column,flex-start,center); 
                            gap:0.5rem;
                            cursor:pointer;
                            //padding-left: 1rem;
                            &:not(:first-child){
                                border-left: 1px solid $grey-350;
                                padding-left:1rem;
                            }
                            .text-copied{
                                background-color: $white;
                                padding: 0.3rem;
                                color:$black;
                                border-radius: 0.4rem;
                                z-index: 1;
                                position: relative;
                                font-size: 0.8rem;  
                                top:-0.75rem; 
                                transition: opacity 0.2s;
                                opacity: 1;
                                &.hide{
                                    visibility: hidden;
                                    opacity: 0;
                                }     
                            }
                            .action-select{
                                position: relative;
                                top:-1rem;
                            }
                            .action-select,button{
                                background: transparent;
                                border: transparent;
                                color: white;
                                padding: 0;
                                @include flex(row,flex-start,center);
                                gap: 0.5rem;
                                font-size: 0.8rem;               
                            }
                            button[disabled],button:disabled{
                                color:$disabled-btn-color;
                            }
                        }
                    }
        
                }
                span.no-data{
                    background-color: $black-800;
                    padding: 1rem;
                    height: 6rem;
                    font-size: 0.9rem;
                    overflow-y: auto;
                    width: 100%;
                    @include scrollbars(0.3rem, lighten($grey-450, 15%), $grey-300);
                }
                .actions{
                    @include flex(column,flex-start,flex-start);
                    button[disabled],button:disabled{
                        color:$disabled-btn-color;
                    }
                    gap:1rem;
                    &__add{
                        background: transparent;
                        border: transparent;
                        color: white;
                        padding: 0;
                        @include flex(row,flex-start,center);
                        gap: 0.5rem;
                        font-size: 0.8rem;               
                    }
                }
            }

        }
        .untrained{
            flex:2;
            @include flex(column,flex-start,flex-start);
            gap:1rem;
            padding: 0 1rem 1rem 1rem;
            .header{
                font-weight:700;
            }
            .content{
                width: 100%;
                @include flex(column,flex-start,flex-start);
                gap:2rem;
                padding:1rem;
                padding-bottom:0;
                background-color:$black-800;
                span.message{
                    padding-right: 1rem;
                    min-height:10rem;
                    max-height: 70vh;
                    font-size: 0.9rem;
                    overflow-y: auto;
                    @include scrollbars(0.3rem, lighten($grey-450, 15%), $grey-300);
                }
                .actions{
                    @include flex(row,flex-start,flex-start);
                    gap:1rem;
                    cursor:pointer;
                    //padding-left: 1rem;
                    .action:not(:first-child){
                        border-left: 1px solid $grey-350;
                        padding-left: 1rem;
                    }
                    
                    // .action-select{
                    //     @include flex(row,flex-start,center);
                    //     font-size: 0.8rem;
                    //     padding-right: 1rem;
                    //     gap:0.5rem;
                    // }
                    .action{
                        .text-copied{
                            background-color: $white;
                            padding: 0.3rem;
                            color:$black;
                            border-radius: 0.4rem;
                            z-index: 1;
                            position: relative;
                            font-size: 0.8rem;  
                            top:-1.5rem; 
                            transition: opacity 0.2s;
                            opacity: 1;
                            &.hide{
                                visibility: hidden;
                                opacity: 0;
                            }     
                        }
                        .action-select{
                            position: relative;
                            top:-1rem;
                        }
                        .actions__add,.action-select{
                            background: transparent;
                            border: transparent;
                            color: white;
                            padding: 0;
                            @include flex(row,flex-start,center);
                            gap: 0.5rem;
                            font-size: 0.8rem;               
                        }
                        button[disabled],button:disabled{
                            color:$disabled-btn-color;
                        }
                    }
                    
                }
            }

        }
    }
}