@import 'assets/scss/mixins';
@import 'assets/scss/variables';
@import 'assets/scss/typography';

.material-input {
    position: relative;
    width: 100%;
    &:not(:last-child) {
        margin-bottom: 1.5rem;
    }
    input:-internal-autofill-selected {
        appearance: menulist-button;
        background-image: none !important;
        background-color: $grey-600 !important;
        color: $white !important;
    }
    input{
        -webkit-box-shadow: 0 0 0 1000px $grey-600 inset !important;
        transition: background-color 5000s ease-in-out 0s !important;
        -webkit-text-fill-color: $white !important;
    }
    input,input:-internal-autofill-selected 
    {
        background-color: $grey-600;
    }
    input[type='text'],input:-internal-autofill-selected 
    {
        color: $white;
        font-size: 0.9rem;
        padding: 1.2rem 0.9rem 0.9rem 0.7rem;
        position: relative;
        display: block;
        border: none;
        width: 100%;
        border-bottom: 0.06rem solid $white;
        box-sizing: border-box;
        &:focus {
            outline: none;
        }
    }
    input[type='text']:focus + label,
    input[type='text']:valid + label,
    input:-internal-autofill-selected :focus + label,
    input:-internal-autofill-selected :valid + label,
    input[type='text']:not(:empty) + label
    {
        top: 0.3rem;
        font-size: 0.75rem;
    }
    label {
        color: $white;
        font-size: 0.9rem;
        font-weight: 300;
        position: absolute;
        pointer-events: none;
        top: 14px;
        transition: 0.2s ease all;
        width: 100%;
        left: 0.75rem;
    }
    .error-message{
        font-size: 0.8rem;
        color:$red-300;
    }
}