@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.tooltip-container {
    @include flex(column, center, center);
    width: toRem(280px);
    height: toRem(70px);

    &__header {
        @include flex(row, flex-end, center);
        padding-right: 10%;
        height: toRem(16px);
        width: 100%;

        &__triangle {
            width: 0;
            height: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-bottom: 20px solid $grey-250;
        }
    }

    &__body {
        @include flex(row, center, center);
        height: toRem(54px);
        width: 100%;
        background-color: $grey-250;
        color: $white;
        font-size: toRem(12px);


        &__content {
            width: 80%;
        }

        &__button {
            @include flex(row, center, center);
            @include square(toRem(5px));
            padding-bottom: toRem(5px);
            height: toRem(54px);
            width: 10%;
        }

        &__button:hover {
            cursor: pointer;
        }

    }

}