@import "/src/assets/scss/variables";
@import "src/assets/scss/mixins";

.Typewriter {
  width: 100%;
  &__cursor {
    position: relative;
    color: transparent;
    &:after {
      content: "";
      width: 0.3rem;
      height: 1rem;
      background-color: $yellow-400;
      position: absolute;
      left: -.01rem;
      top: .03rem;
    }
  }
}
.chatContent{
    @include flex(row,flex-start,flex-start);
    gap:1rem;
    padding: 1.5rem 0.65rem !important;
    max-width:53rem!important;
    &__untrained{
        flex:2;
        @include flex(column,flex-start,flex-start);
        gap:1.5rem;
        padding: 0 1rem 1rem 1rem;
        
        .header{
            font-weight:700;
        }
        .content{
            padding-right: 1rem;
            height:13rem;
            overflow-y: auto;
            @include scrollbars(0.3rem, lighten($grey-450, 15%), $grey-300);
        }
        .actions{
            @include flex(column,flex-start,flex-start);
            button[disabled],button:disabled{
                color:$disabled-btn-color;
              }
            gap:1rem;
            &__add{
                background: transparent;
                border: transparent;
                color: white;
                padding: 0;
                @include flex(row,flex-start,center);
                gap: 0.5rem;
                font-size: 0.8rem;               
            }
            &__view{
                background: transparent;
                border: 1px solid white;
                color: white;
                padding: 0.5rem;
                @include flex(row,flex-start,center);
                font-weight: 700;
                gap: 0.5rem;            
            }
        }
    }
    &__trained{
        flex:2;
        @include flex(column,flex-start,flex-start);
        gap:1.5rem;
        padding: 0 1rem;
        border-left: 1px solid #4E515B;
        .trained-header{
            width: 100%;
            @include flex(row,space-between,center);
            .header{
                font-weight:700;
            }
        }
       
        .content{
            padding-right:1rem;
            height:10rem;
            overflow-y: auto;
            @include scrollbars(0.3rem, lighten($grey-450, 15%), $grey-300);
        }
        .actions{
            @include flex(row,space-between,flex-start);
            flex-wrap: wrap;
            gap:1rem;
            width:100%;
            button[disabled],button:disabled{
                color:$disabled-btn-color;
              }
            &__doc{
                position: relative;
                @include flex(row,flex-start,center);
                gap:0.5rem;
                align-self: flex-end;
                width:100%;
                .citation-modal{
                    background-color: $black;
                    position: absolute;
                    //top: -7rem;
                    padding: 0.5rem;
                    z-index: 1;
                    bottom: 125%;
                   
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity 0.3s;
                    max-width: 15rem;
                    @include flex(row,flex-start,flex-start);
                    gap:2rem;
                    &__label{
                        flex: 3;
                        @include flex(column,flex-start,flex-start);
                        gap:1rem;
                    }
                    &__values{
                        flex:3;
                        @include flex(column,flex-start,flex-start);
                        gap:1rem;
                    }
                    .doc-value{
                        cursor:pointer;
                        cursor: pointer;
                        word-wrap: break-word;
                        display: inline-block;
                        width: 8rem;
                    }
                    &__score{
                        @include flex(row,flex-start,center);
                        gap:1rem;
                    }
                    &__source{
                        @include flex(row,flex-start,center);
                        gap:1rem;
                    }
                    &__pages{
                        @include flex(row,flex-start,center);
                        gap:1rem;
                    }
                   &::after {
                    content: "";
                    position: absolute;
                    top: 98%;
                    left: 40%;
                    margin-left: -5px;
                    border-width: 10px;
                    border-style: solid;
                    border-color: $black transparent transparent transparent;
                  }
                }
                &:hover .citation-modal{
                    visibility: visible;
                    opacity: 1;
                }
                .doc-confidence.excellent{
                    color: $green-400;
                }
                .doc-confidence.medium{
                    color: $grey-300;
                }
                .doc-confidence.low{
                    color: $red-300;
                }
            }
            &__view{
                background: transparent;
                border: transparent;
                color: $yellow-400;
                padding: 0;
                @include flex(row,flex-start,center);
                gap: 0.2rem;
                font-size: 0.8rem;               
            }
            &__provide-feedback,&__mark-accurate{
                background: transparent;
                color: white;
                border: 1px solid white;
                padding: 0.2rem 0.5rem;
                min-width: 10rem;
                @include flex(row,flex-start,center);
                gap: 0.2rem;
                font-size: 0.8rem;  
            }
        }
    }
    &__maximize{
        flex:0.1;
        position: relative;
        right:-3rem;
        img{
            cursor: pointer;
        }
    }
}
.react-typewriter-text-wrap{
  width: 100%;
  background-color: $bot-bg;
  border-top-left-radius: 0.3rem !important;
  color: $white;
  max-width: 30rem;
  border-radius: 1rem;
  padding: 0.75rem 1.5rem;
  line-height: 1.2rem;
  font-size: 0.8rem;
   .react-typewriter-text{
    line-height: 1.2rem;
    font-size: 0.8rem;
    font-weight: normal;
   }
}
.text-message{
.add-cursor-animate {
  background-color: $yellow-400;
  display: inline;
  padding: 0 1px;
  &.hide{
    display:none;
  }
}
}
