@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.gpo-chat-header {
  background-color: $bg-color-dark;
  box-shadow: 2px 1px 2px 1px rgb(233, 234, 235, 0.05);
  position: relative;
  color: $white;

  &__container {
    @include flex(row, space-between, center);
    padding: 1rem 3rem;
    margin: 0 auto;

    .gpo-logo {
      span {
        font: {
          weight: 600;
        }
      }
    }

    .gpo-profile {
      img :nth-child(2){
        border-radius: 50%;
        @include square(2.5rem);
      }

      &__details {
        position: absolute;
        background-color: #fff;
        color: black;
        @include size(16rem, auto);
        animation: move .3s ease;
        top: 4.2rem;
        right: 13vw;
        box-shadow: 2px 1px 2px 2px rgb(0 0 0 / 5%);
        border-radius: 0.3rem;
        z-index: 100;

        @keyframes move {
          0% {
            transform: translateY(-1.5rem);
            opacity: 0;
          }

          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }
      }
    }
  }

  .profile-name {
    @include flex(column, center, center);
    padding: 1rem 1rem 1.5rem;
    border-bottom: 0.1rem solid #C4C4CD;
    margin: 0 1.5rem;

    img {
      border-radius: 50%;
      @include square(3rem);
    }

    span {
      color: $black;
      padding-top: 1rem;
      text-align: center;
      line-height: 0.2rem;

      font: {
        weight: 400;
        size: 0.8rem;
      }

      &.displayName {
        font: {
          weight: 600;
          size: 1rem;
        }
      }
    }
  }

  &__container {
    @include flex(row, space-between, center);
    padding: 1rem;
    width: 95vw;
    margin: 0 auto;

    .gpo-logo {
      span {
        font: {
          weight: 600;
        }
      }
    }

    .gpo-profile {
      &__container {
        @include flex(row, space-between, center);
        width: 100%;
        gap: toRem(20px);
      }

      img:nth-child(1):hover {
        cursor: pointer;
      }

      img:nth-child(2) {
        border-radius: 50%;
        @include square(2.5rem);
      }

      &__details {
        position: absolute;
        background-color: #fff;
        color: black;
        @include size(16rem, auto);
        animation: move .3s ease;
        top: 4.2rem;
        right: 13vw;
        box-shadow: 2px 1px 2px 2px rgb(0 0 0 / 5%);
        border-radius: 0.3rem;
        z-index: 100;

        @keyframes move {
          0% {
            transform: translateY(-1.5rem);
            opacity: 0;
          }

          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }

        .profile-name {
          @include flex(column, center, center);
          padding: 1rem 1rem 1.5rem;
          border-bottom: 0.1rem solid #C4C4CD;
          margin: 0 1.5rem;

          img {
            border-radius: 50%;
            @include square(3rem);
          }

          span {
            color: $black;
            padding-top: 1rem;
            text-align: center;
            line-height: 0.2rem;

            font: {
              weight: 400;
              size: 0.8rem;
            }

            &.displayName {
              font: {
                weight: 600;
                size: 1rem;
              }
            }
          }
        }

        .logout {
          @include flex(row, space-between, center);
          padding: 1rem;
          cursor: pointer;

          span {
            color: #1A1A24;
            text-align: center;
            line-height: 0.2rem;
            font-weight: 400;
            font-size: 0.87rem;
            top: 0.2rem;
            @include flex(row, flex-start, center);
          }

          img {
            @include square(1.2rem);
            position: relative;
            left: .2rem;
            margin-right: 0.5rem;

          }
        }

        &:after,
        &:before {
          bottom: 100%;
          left: 92%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        &:after {
          border-color: rgba(238, 238, 238, 0);
          border-bottom-color: #ffffff;
          border-width: 0.5rem;
          margin-left: -1.1rem;
        }

        &:before {
          border-color: rgba(204, 204, 204, 0);
          border-bottom-color: #c4c4cd;
          border-width: 0.5rem;
          margin-left: -1.1rem;
        }
      }
    }
  }
  .notification-circle{
    @include square(toRem(7px));
    border-radius: 50%;
    background-color: $blue-200;
    position: relative;
    bottom: toRem(20px);
    left: toRem(23px);
  }
  .tooltip{
    position: absolute;
    top: toRem(50px);
    right: toRem(65px);
    z-index: 100;
  }
}
